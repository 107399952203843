<template>
  <div class="relative">
    <div class="flex items-center mb-2 justify-start">
      <span class="w-44">Địa chỉ trụ sở</span>

      <div class="flex items-center w-full gap-2">
        <!-- Tỉnh - Thành Phố -->
        <el-select
          v-model="city"
          placeholder="Tỉnh - Thành Phố"
          @change="changeCity"
          filterable
          clearable
        >
          <el-option
            v-for="(item, index) in city_options"
            :value="item"
            :key="index"
          >
            {{ item[0] }}
          </el-option>
        </el-select>

        <!-- Quận - Huyện -->
        <el-select
          v-model="district"
          placeholder="Quận - Huyện"
          @change="changeDistrict"
          filterable
          clearable
        >
          <el-option
            v-for="(item, index) in district_options"
            :value="item"
            :key="index"
          >
            {{ item[0] }}
          </el-option>
        </el-select>

        <!-- Phường - Xã -->
        <el-select
          v-model="ward"
          placeholder="Phường - Xã"
          filterable
          clearable
        >
          <el-option
            v-for="(item, index) in ward_options"
            :value="item"
            :key="index"
          >
            {{ item }}
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
// import { URL } from "@/api/company";
import dvhc from "@/assets/data/dvhc.json";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      city_options: dvhc,
      district_options: [],
      ward_options: [],
      city: null,
      district: null,
      ward: null,
    };
  },

  props: ["reset"],

  methods: {
    async getDVHC() {
      // Waste bandwidth => move to Frontend
      // const result = await this.$request({ url: URL.GET_DVHC });
      // const res = result.data;
      // console.log('res', res);
      // if (res.code == 200) {
      //   this.city_options = res.data;
      // }
    },

    changeCity(item) {
      this.city = item[0];
      this.district = null;
      this.ward = null;
      this.district_options = item[1];
      this.ward_options = [];
    },

    changeDistrict(item) {
      this.district = item[0];
      this.ward = null;
      this.ward_options = item[1];
    },
  },
  computed: {
    ...mapGetters(["getAuthUser"]),
  },

  watch: {
    city() {
      if (!this.city) {
        this.district_options = [];
      }
      this.$emit("city:update", this.city);
    },
    district() {
      this.$emit("district:update", this.district);
    },
    ward() {
      this.$emit("ward:update", this.ward);
    },
    reset() {
      this.city = null;
      this.district = null;
      this.ward = null;
    },
  },
};
</script>
