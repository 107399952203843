const URL = {
  LIST_USER: "/users",
  CREATE_USER: "/users",
  UPDATE_USER: "/users/:id",
  UPDATE_COMPANY_USER: "/users/company/:id",
  ROLES_USER: "/users/roles",
  GET_USER_LOGGING: "/users/:id/get_user_logging",
};
const COMPANY_ADD_USER = "/users";
export { URL, COMPANY_ADD_USER };
