<template>
  <el-tooltip
    class="item"
    effect="dark"
    :content="status"
    :placement="placement"
  >
    <div class="text-center">
      <i v-if="!status" class="el-icon-question text-yellow-600" />
      <i v-else-if="isWarning(status)" class="el-icon-warning text-red-600" />
      <i v-else class="el-icon-success text-green-600" />
    </div>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    status: {},
    placement: {
      type: String,
      default: "left",
    },
  },

  methods: {
    isWarning(status) {
      return (
        status.includes("ngừng") ||
        status.includes("nghỉ") ||
        status.includes("không") ||
        status.includes("dừng")
      );
    },
  },
};
</script>

<style scoped>
i {
  font-size: 1.25rem;
}
</style>
