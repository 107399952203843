function checkTaxCode(taxCode) {
  taxCode = taxCode.toString().split("-")[0];
  const isOnlyDigits = /^\d{10}$/.test(taxCode);
  if (!isOnlyDigits) return false;
  const mst = taxCode.toString().split("");
  const total =
    10 -
    (parseInt(mst[0]) * 31 +
      parseInt(mst[1]) * 29 +
      parseInt(mst[2]) * 23 +
      parseInt(mst[3]) * 19 +
      parseInt(mst[4]) * 17 +
      parseInt(mst[5]) * 13 +
      parseInt(mst[6]) * 7 +
      parseInt(mst[7]) * 5 +
      parseInt(mst[8]) * 3);
  let remainder = total % 11;
  remainder && (remainder += 11);
  return remainder == parseInt(mst[9]);
}

function checkTaxCodeRule(errorMessage) {
  return this.test(`test-code`, errorMessage, function (value) {
    const { path, createError } = this;
    return checkTaxCode(value) || createError({ path, message: errorMessage });
  });
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export { checkTaxCode, checkTaxCodeRule, validateEmail };
